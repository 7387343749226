import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
export default class extends Controller {
    static values = { open: Boolean }; // Defines a value called "open" that is a boolean using data-modal-open-value="true" or "false" for state on an element
    static targets = ["container", "card"]; // Get the element with the data-modal-target="container" attribute
    

    connect() {
        // register event listeners and handlers
        useClickOutside(this, { element: this.cardTarget });
        this.handleTabClicks();
    }
    
    toggle() {
        this.openValue = !this.openValue;
        this.updateVisibility();
    }
    
    close() {
        this.openValue = false;
        this.updateVisibility();
    }

    // clickOutside(event) closes the modal when clicking outside of it (using the stimulus-use plugin)
    clickOutside(event) {
        event.preventDefault();
        this.close();
    }
    
    updateVisibility() {
        const visibleZIndex = 10001;
        const notVisibleZIndex = 10000;
        const menuBar = document.querySelector('.menu-bar');
        const navbar = document.querySelector('.public-navbar');

        // if the modal is being opened
        if (this.openValue) {
            if (navbar) {
                menuBar.style.zIndex = visibleZIndex; // take on the z-index of the navbar
                navbar.style.zIndex = notVisibleZIndex; // set the z-index of the navbar to 0
            }

            this.containerTarget.classList.remove('tw-hidden'); // remove the hidden class (show the modal)
            return;
        }
        
        // if the modal is being closed
        if (navbar) {
            menuBar.style.zIndex = notVisibleZIndex; // set the z-index of the modal back to 0
            navbar.style.zIndex = visibleZIndex; // set the z-index of the navbar back to its original value
        }
        this.containerTarget.classList.add('tw-hidden'); // add it back (hide the modal)
    }

    handleTabClicks() {
        document.querySelectorAll('.tab').forEach(tab => {
            tab.addEventListener('click', () => {
                this.close() // close the modal when a tab is clicked (only on mobile/tablet)
            });
        });
    }

}