import _, { set } from 'lodash';
import clsx from 'clsx';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';
import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { HomeIcon } from '@heroicons/react/20/solid'
import { useFloating, FloatingArrow, arrow } from '@floating-ui/react';
import { offset } from '@floating-ui/dom';


const StoreBreadcrumb = ({ company: initialCompanyData  }) => {
    const breadcrumbRef = useRef(null);
    breadcrumbRef.current = breadcrumbRef.current || document.getElementById('store-breadcrumb');
    const [pathName, setPathName] = useState(window.location.pathname);
    const [pathArray, setPathArray] = useState(pathName.split('/').slice(1));
    const [rootPath, setRootPath] = useState(`/${pathArray[0]}/${pathArray[1]}`);
    const [lastPath, setLastPath] = useState(pathArray[pathArray.length - 1]);
    const [isHomePath, setIsHomePath] = useState(
        pathName === rootPath || pathName === rootPath + "/" || 
        pathName === rootPath + '/featured' || pathName === rootPath + '/menu'
    );
    
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [company, setCompanyData] = useState(initialCompanyData);
    const [categories, setCategoriesData] = useState([])
    const [currentCategoryId, setCurrentCategoryIdData] = useState(Number.NEGATIVE_INFINITY);
    const [currentCategory, setCurrentCategoryData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSpecialCategory, setIsSpecialCategory] = useState(false);
    const [currentScrollspyCategoryName, setCurrentScrollspyCategoryName] = useState(""); // Track current category name
    const currentBreadCrumbCategoryState = { current: null };
    const [showTooltip, setShowTooltip] = useState(true);

    const [tooltipCount, setTooltipCount, removeTooltipCount] = useLocalStorage('mobileBreadcrumbTooltipCount', 0);
    const smallerThanDesktop = useMediaQuery('(max-width: 1023px)') // check if the screen size is smaller than Tailwind desktop breakpoint
    const shouldShowMenuTooltip = showTooltip && tooltipCount < 2 && smallerThanDesktop;

    const arrowRef = useRef(null);
    const {refs, floatingStyles, context } = useFloating({
        strategy: 'absolute',
        placement: 'bottom-start',
        middleware: [
            offset(({rects}) => ({
                mainAxis: 10,
                alignmentAxis: 0.5 * rects.floating.width,
            })), // Offset Middleware
            arrow({
                element: arrowRef,
            }), // Arrow Middleware
        ]
    });

    useEffect(() => {
        const updateBreadcrumbData = (event) => {
            const { company: companyData, categories: categoriesData  } = event.detail.productData;
            setCompanyData(companyData);
            setCategoriesData(categoriesData);

            const currentCategoryIdData = event.detail.currentCategoryId;
            setCurrentCategoryIdData(currentCategoryIdData);
            const currentCategoryData = event.detail.currentCategory;
            const isSpecialCategoryData = currentCategoryData === undefined || currentCategoryIdData === -1;
            
            if (isSpecialCategoryData) {
                setIsSpecialCategory(true);
                setCurrentCategoryData(null);
            } else {
                setIsSpecialCategory(false);
                setCurrentCategoryData(currentCategoryData);
            }

            const newPathName = window.location.pathname;
            const newPathArray = newPathName.split('/').slice(1);
            const newRootPath = `/${newPathArray[0]}/${newPathArray[1]}`;
            const newLastPath = newPathArray[newPathArray.length - 1];
            const newIsHomePath = newPathName === newRootPath || newPathName === newRootPath + "/" || 
                newPathName === newRootPath + '/featured' || newPathName === newRootPath + '/menu';

            setPathName(newPathName);
            setPathArray(newPathArray);
            setRootPath(newRootPath);
            setLastPath(newLastPath);
            setIsHomePath(newIsHomePath);

            calculateBreadcrumbItems(categoriesData, currentCategoryData, isSpecialCategoryData);
        };

        // Listen for product data updates and update the breadcrumb state accordingly
        window.addEventListener('productDataUpdated', updateBreadcrumbData);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('productDataUpdated', updateBreadcrumbData);
        };
    }, []);

    useEffect(() => {
        if (!categories || !currentCategory || !isSpecialCategory) {
            return;
        }
        // calculate the breadcrumb items based on the current category and company data
        calculateBreadcrumbItems(categories, currentCategory, isSpecialCategory);
    }, [categories, currentCategory, isSpecialCategory, isLoaded]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                if (shouldShowMenuTooltip) hideFloatingTooltip();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showTooltip, smallerThanDesktop]);

    // // Monitor category visibility
    // useLayoutEffect(() => {
    //     const handleScroll = () => {
    //         // Don't show scrollspy category in breadcrumb until it's about to be sticky
    //         if (breadcrumbRef.current.getBoundingClientRect().top > 150) {
    //             currentBreadCrumbCategoryState.current = null;
    //             setBreadcrumbItems([
    //                 { name: _.capitalize(lastPath), subCategories: null },
    //             ]);
    //             return;
    //         }

    //         // Find the .product-category-row that has part of its div closest (any part not just top) to 1/3 from the top of the viewport
    //         const categoryRows = document.querySelectorAll('.product-category-row');
    //         let closestCategory = null;
    //         let closestDistance = Number.POSITIVE_INFINITY;

    //         categoryRows.forEach((categoryRow) => {
    //             const distanceFromTop = Math.abs(categoryRow.getBoundingClientRect().top - window.innerHeight / 3);
    //             const distanceFromBottom = Math.abs(categoryRow.getBoundingClientRect().bottom - window.innerHeight / 3);
    //             if (distanceFromTop < closestDistance || distanceFromBottom < closestDistance) {
    //                 closestCategory = categoryRow;
    //                 closestDistance = Math.min(distanceFromTop, distanceFromBottom);
    //             }
    //         });

    //         if (!closestCategory) return;
    //         const title = closestCategory.querySelector('.product-category-title');
    //         const categoryName = title.textContent;

    //         if (currentBreadCrumbCategoryState.current !== categoryName) {
    //             setCurrentScrollspyCategoryName(categoryName);
    //             currentBreadCrumbCategoryState.current = categoryName;

    //             if (!categoryName || breadcrumbItems.length === 0) return;

    //             setBreadcrumbItems([
    //                 ...breadcrumbItems,
    //                 { name: null, subCategories: null, isScrollspy: true },
    //             ]);
    //         }
    //     }

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [categories, currentCategory]); // Re-run when categories change

    const findFirstTabAndClick = () => {
        const firstTab = document.querySelectorAll('.tab')[0]; // get the first tab from sidebar

        // TODO: handle the case where there are no tabs (no sidebar)
        if (!firstTab) {
            return;
        }

        firstTab.click(); // click the first tab
        setIsLoaded(false); // set the loaded state to false
    }

    const calculateBreadcrumbItems = ( subCategoriesData, currentCategoryData, isSpecialCategoryData) => {
        // if the current category is a special category (featured, menu, etc.) set the breadcrumb to the capitalization of the last path 
        if (isSpecialCategoryData) {
            // based on last element in pathArray, set the breadcrumb to the company name
            setBreadcrumbItems([
                { name: _.capitalize(lastPath), subCategories: null },
            ]);
        } else {
            // if the current category is not the featured category, set the breadcrumb to the company name and the current category
            setBreadcrumbItems([
                { name: currentCategoryData.title, subCategories: subCategoriesData },
            ])
        }

        setIsLoaded(true);
    }

    const handleBreadcrumbClick = () => {
        hideFloatingTooltip();
        if ($('[data-controller="store_sidebar"]').is(':visible')) {
            findFirstTabAndClick();
        } else {
            document.querySelector('.sidebar-modal-button').click();
        }
    }

    const renderFloatingTooltip = () => {
        return (
            <div ref={refs.setFloating} style={floatingStyles} onClick={() => hideFloatingTooltip()} className="md:tw-hidden tw-bg-green-600 tw-text-white tw-px-2 tw-py-1 tw-rounded-md tw-rounded-tl-sm tw-shadow-md tw-w-max">
                <FloatingArrow ref={arrowRef} context={context} className='tw-fill-green-600 [&>path:first-of-type]:tw-stroke-green-600 [&>path:last-of-type]:tw-stroke-green-600 tw-top-[-13px]' />
                Click to explore menu
            </div>
        )
    }

    const hideFloatingTooltip = () => {
        // set a local storage variable to count the number of times the tooltip has been shown
        // increment the count each time the tooltip is shown
        // if the count is greater than 3, don't show the tooltip anymore
        if (shouldShowMenuTooltip) {
            setTooltipCount((x) => x + 1);
    
            setShowTooltip(false);
        }
    }

    return (
        <div className='tw-flex tw-flex-row'>
            <nav ref={refs.setReference} id="store-breadcrumb" onClick={handleBreadcrumbClick} aria-label="Breadcrumb" className="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-3 tw-bg-white tw-shadow tw-border tw-border-solid tw-border-vroom-gray-600 tw-rounded-md tw-z-10">
                {/* {showTooltip && (
                    <div className="tw-absolute tw-bottom-0 -tw-right-8 tw-bg-green-500 tw-text-white tw-px-2 tw-py-1 tw-rounded-md tw-shadow-md">
                        Click to explore menu
                    </div>
                )} */}
                <div role="list" className="tw-inline-flex tw-items-center tw-list-none">
                    <div key="home" className={clsx(
                        isHomePath && "tw-text-gray-700",
                        "tw-text-gray-400 hover:tw-text-gray-500 tw-cursor-pointer tw-flex tw-items-center tw-justify-center"
                    )}>
                        <HomeIcon aria-hidden="true" className="tw-size-5 tw-shrink-0" />
                        <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="tw-size-5 tw-shrink-0 tw-text-gray-300">
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                        </svg>
                        {isHomePath && 
                            <span className="tw-ml-1 tw-h-5 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-700">
                                {$('.tab.active').data('title')}
                            </span>
                        }
                        <span className="sr-only">Home</span>
                    </div>
                    {/* render the rest of the breadcrumbs if  */}
                    {!isHomePath && breadcrumbItems.map(({ name, subCategories, isScrollspy }, index) => (
                        <div key={name+index}>
                            <div className="tw-flex tw-flex-row tw-items-end">
                                <span className="tw-ml-1 tw-h-5 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-text-gray-700">
                                    {isScrollspy ? currentScrollspyCategoryName : $('.tab.active').data('title')}
                                </span>
                                {/* render trailing slash if we're not the last index */}
                                {index !== breadcrumbItems.length - 1 && (
                                    <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="tw-size-5 tw-shrink-0 tw-text-gray-300">
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </nav>
            {shouldShowMenuTooltip && renderFloatingTooltip()}
        </div>
    )
};

export default StoreBreadcrumb;