import React from 'react';
import { useInView } from 'react-intersection-observer';

const StickyComponent = ({ topOffset, children }) => {
    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: `-${topOffset}px 0px 0px 0px`,
    });

    return (
        <div
            ref={ref}
            style={{
                position: 'sticky',
                top: topOffset,
                zIndex: inView ? 20 : 0,
                transition: 'z-index 0.3s',
            }}
        >
            {children}
        </div>
    );
};

export default StickyComponent;
