import React from 'react';
import { createRoot } from 'react-dom/client';
import { Controller } from "@hotwired/stimulus";
import StoreProductGrid from './modules/StoreProductGrid';

export default class extends Controller {

    connect() {
        this.initializeReact();
        this.addEventListeners();
    }

    disconnect() {
        if (this.root) {
            this.root.unmount();
        }
        this.removeEventListeners();
    }

    initializeReact() {
        const storeGridElement = document.getElementById('store-product-grid');
        if (storeGridElement) {
            this.root = createRoot(storeGridElement);
            this.root.render(React.createElement(StoreProductGrid, {
                onCategoryChange: this.hideSkeleton.bind(this)
            }));
        } else {
            logError('Element with id "store-product-grid" not found during StoreProductGrid initialization');
        }
    }

    addEventListeners() {
        if (!this.hasListenersAdded) {
            window.addEventListener('longCategoryLoad', this.showSkeleton.bind(this));
            this.hasListenersAdded = true;
        }
    }

    removeEventListeners() {
        if (this.hasListenersAdded) {
            window.removeEventListener('longCategoryLoad', this.showSkeleton.bind(this));
            this.hasListenersAdded = false;
        }
    }

    showSkeleton() {
        const storeGrid = document.getElementById('store-product-grid');
        const skeleton = document.getElementById('skeleton-loader');
        storeGrid.style.display = 'none';
        skeleton.style.display = 'block';
    }

    hideSkeleton() {
        const storeGrid = document.getElementById('store-product-grid');
        const skeleton = document.getElementById('skeleton-loader');
        skeleton.style.display = 'none';
        storeGrid.style.display = 'block';
    }

}