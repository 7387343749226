import clsx from 'clsx';
import React, { useEffect, useCallback, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

// SUBCOMPONENTS
import ProductElement from './ProductElement';


const ProductRow = ({ isStoreOpen, category, products, company, productAvailabiltyMap, isDefaultGridView, storeStyleTemplate, isOrderAgain }) => {
    const [isGridView, setIsGridView] = useState(isDefaultGridView ?? false);
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: 'start',
        containScroll: false,
        loop: true,
        slidesToScroll: 2,
        // Because we don't have responsive product elements, we need to adjust the number of slides to scroll based on the screen width
        breakpoints: {
            '(min-width: 500px)': { slidesToScroll: 3 },
            '(min-width: 656px)': { slidesToScroll: 4 },
            '(min-width: 768px)': { slidesToScroll: 3 },
            '(min-width: 896px)': { slidesToScroll: 4 },
            '(min-width: 1024px)': { slidesToScroll: 3 },
            '(min-width: 1228px)': { slidesToScroll: 4 },
            '(min-width: 1462px)': { slidesToScroll: 5 },
        },
    });
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    const isFeaturedCategory = category.id === -1;

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    const updateButtons = useCallback(() => {
        if (!emblaApi) return;
        setPrevDisabled(!emblaApi.canScrollPrev());
        setNextDisabled(!emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;

        // Attach event listeners to Embla
        emblaApi.on('init', updateButtons);
        emblaApi.on('select', updateButtons);

        // Initial update
        updateButtons();

        // Cleanup listeners on unmount
        return () => {
            emblaApi.off('init', updateButtons);
            emblaApi.off('select', updateButtons);
        };
    }, [emblaApi, updateButtons]);

    const toggleView = () => {
        setIsGridView(!isGridView);

        if (!isGridView) {
            const categoryElement = document.querySelector(`.product-row[data-id="${category.id}"]`);
            const productCard = categoryElement.querySelector('.product-card');
            const scrollTop = $(productCard).offset().top - ( $(window).height() - $(productCard).outerHeight(true) ) / 2 - 50;

            if (scrollTop - $(window).scrollTop() > 25) {
                $('html,body').animate({ scrollTop: scrollTop }, 200);
            }
        }
    }

    // To avoid flicker when toggling between grid and carousel view, eager load
    // the first few products in the ProductRow and lazy load the rest
    let productsToEagerLoad = 5;
    if (window.innerWidth < 768) {
        productsToEagerLoad = 3;
    } else if (window.innerWidth < 1228) {
        productsToEagerLoad = 4;
    }

    return (
        <div data-id={category.id} className={clsx('product-row', !isStoreOpen && 'tw-relative', 'tw-px-2 md:tw-px-0')}>
            <div 
                style={{ backgroundColor: storeStyleTemplate?.background_color ?? null }} // set the whitelabel background color here if it exists
                className={clsx(
                isDefaultGridView && "tw-hidden",
                isGridView && isFeaturedCategory &&  "tw-top-[168px] tw-bg-white tw-z-[2] tw-w-full", // leave extra space for featured category header
                isGridView && !isFeaturedCategory && "tw-top-[132px] tw-bg-white tw-z-[2] tw-w-full",
                "tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-2")}>
                <span data-id={category.id} className="product-category-title tw-text-2xl tw-font-semibold tw-inline-block tw-mt-[10px]">{category.title}</span>
                {/* Carousel Buttons */}
                <div className='tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center '>
                    {/* View All, Text Button */}
                    <span 
                        className={clsx(
                            "tw-vroom-btn tw-font-semibold tw-text-sm tw-rounded-full tw-py-1 tw-px-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-min-w-[75px]"
                        )} 
                        onClick={toggleView}
                    >
                        {isGridView ? 
                            <span className='tw-flex tw-flex-row tw-items-center tw-justify-center tw-px-1'> 
                                <ChevronDownIcon className='tw-size-5' /> 
                                Close
                            </span> : // Back to carousel view Button
                            'View All' // View all products in grid view
                        }
                    </span>
                </div>
            </div>
            {isGridView ? (
                // Grid View
                <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-2 md:tw-gap-3 tw-mt-[5px]">
                    {products.map((product, index) => (
                        <div key={product.id} className="tw-flex tw-justify-center">
                            <ProductElement 
                                product={product} 
                                company={company}
                                isStoreOpen={isStoreOpen}
                                availabilityInfo={productAvailabiltyMap[Number(product.id)]} 
                                isOrderAgain={isOrderAgain}
                                lazyLoadAvatar={index >= productsToEagerLoad}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                // Carousel View
                <div className="tw-mt-[5px]">
                    <div className="tw-relative">
                        <div
                            onClick={scrollPrev} 
                            className={clsx(
                                isGridView && "tw-hidden",
                            prevDisabled  && "tw-hidden",
                            "tw-absolute tw-z-10 tw-vroom-btn tw-size-14 tw-left-0 tw-top-[90px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer")} 
                        >
                            <ChevronLeft size={20} />
                        </div>
                        <div
                            onClick={scrollNext} 
                            className={clsx(
                                nextDisabled && "tw-bg-vroom-gray-600",
                                "tw-absolute tw-z-10 tw-vroom-btn tw-size-14 tw-right-0 tw-top-[90px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer"
                            )}
                        >
                            <ChevronRight size={20} />
                        </div>
                    </div>
                    <div ref={emblaRef} className='product-row-carousel-overflow-wrapper tw-relative tw-overflow-hidden tw-ml-2 tw-pl-2 tw-flex tw-flex-row tw-gap-1 md:tw-gap-4 '>
                        {/* Next/Prev Buttons: attached to center of  */}
                        <div className="product-row-carousel-container tw-flex tw-gap-2 md:tw-gap-4">
                            {products.map((product, index) => (
                                <ProductElement 
                                    key={product.id} 
                                    product={product} 
                                    company={company} 
                                    isStoreOpen={isStoreOpen}
                                    availabilityInfo={productAvailabiltyMap[Number(product.id)]}
                                    isOrderAgain={isOrderAgain}
                                    lazyLoadAvatar={index >= productsToEagerLoad}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default ProductRow;