import clsx from 'clsx';
import { Switch, Case, Default} from 'react-if'
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import ProductRow from './ProductRow';
import MenuTile from './MenuTile';
import StoreBreadcrumb from './StoreBreadcrumb';
import StickyComponent from './StickyComponent';

export const MENU_LAYOUTS = {
    featured_products: 0,
    featured_primary_categories: 1,
    featured_secondary_categories: 2,
};

const StoreProductGrid = ({ onCategoryChange, onMounted,
    initialCategoryId, initialCategories, initialProducts, initialCompanyData,
    initialStoreAvailabilityInfo, initialKioskMode, initialSponsored, initalProductsForOrderAgain, initialProductAvailabiltyMap, initialStoreStyleTemplate }) => {
    // STATE
    const [storeAvailabilityInfo, setStoreAvailabilityInfo] = useState(initialStoreAvailabilityInfo ?? {});
    const [isKioskMode, setIsKioskMode] = useState(initialKioskMode ?? false);
    const [company, setCompanyData] = useState(initialCompanyData ?? {});
    const [currentCategoryId, setCurrentCategoryId] = useState(initialCategoryId ?? Number.NEGATIVE_INFINITY);
    const [categories, setCategories] = useState(initialCategories ?? []);
    const [products, setProducts] = useState(initialProducts ?? []);
    const [sponsored, setSponsored] = useState(initialSponsored ?? []);
    const [productsForOrderAgain, setProductsForOrderAgain] = useState(initalProductsForOrderAgain ?? []);
    const [productAvailabiltyMap, setProductAvailabiltyMap] = useState(initialProductAvailabiltyMap ?? undefined);
    const [storeStyleTemplate, setStoreStyleTemplate] = useState(initialStoreStyleTemplate ?? []);
    const [sortedProductData, setSortedProductData] = useState([]);
    const [sortedFeaturedProductData, setSortedFeaturedProductData] = useState([]);
    const [isFeaturedSection, setIsFeaturedSection] = useState(false);

    // SIDE-EFFECTS
    // this has no dependencies in the array so it only runs once (on mount)
    useEffect(() => {
        const updateProductData = (event) => {
            const currentCategoryId = event.detail.currentCategoryId;
            const { kioskMode, company, sponsored, categories, storeAvailabilityInfo,
                products, productsForOrderAgainSection, productAvailabiltyMap, sortedProductData,
                isFeaturedSection, sortedFeaturedProductData
            } = event.detail.productData;
            
            setSortedProductData(sortedProductData);
            setIsFeaturedSection(isFeaturedSection);
            setSortedFeaturedProductData(sortedFeaturedProductData);

            setIsKioskMode(kioskMode);
            setCompanyData(company);
            setStoreStyleTemplate(company.store_style_template);
            setCurrentCategoryId(currentCategoryId);
            setCategories(categories);
            setProducts(products);
            setSponsored(sponsored);
            setStoreAvailabilityInfo(storeAvailabilityInfo);
            setProductsForOrderAgain(productsForOrderAgainSection);
            setProductAvailabiltyMap(productAvailabiltyMap);
        };

        // Listen for product data updates and update the state accordingly
        window.addEventListener('productDataUpdated', updateProductData);
        window.reactStoreGridLoaded = true;

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('productDataUpdated', updateProductData);
            window.reactStoreGridLoaded = false;
        };
    }, []);

    useLayoutEffect(() => {
        if (products.length > 0 || categories.length > 0) {
            onCategoryChange();
            window.dispatchEvent(new CustomEvent('storeProductGridRendered', { detail: { storeProductGridRendered: true } }));
        }
    }, [products, categories]);

    const { isOpen: isStoreOpen, message: storeAlertMessage, showWarning } = storeAvailabilityInfo;
    const shouldShowMenuTiles = currentCategoryId == -1 && [MENU_LAYOUTS.featured_primary_categories, MENU_LAYOUTS.featured_secondary_categories].includes(company.menu_layout);
    
    const renderStoreAlertMessage = () => {
        if (!storeAlertMessage) return null; // don't show the alert message if there isn't one
        if (!showWarning) return null; // don't show the alert message if we're not supposed to

        // otherwise, show the alert message
        return (
            <div className="tw-w-full tw-top-[140px] tw-z-10 tw-bg-[#f4edf9] tw-text-[#006089] tw-text-center tw-py-2 tw-mb-4 md:tw-text-lg">
                {storeAlertMessage}
            </div>
        );
    };

    const renderFeaturedHeaderTextSection = () => {
        if (shouldShowMenuTiles && (company.featured_text == null || company.featured_text === "")) {
            return <div className="tw-h-10"></div>;
        }

        const featuredText = company.featured_text || "Popular Items";
        return (
            <div id="featured-text-header" className={clsx(
                "tw-text-black tw-text-2xl tw-mt-4 tw-pb-1 tw-px-2 tw-font-bold tw-mb-4 tw-z-10 tw-top-[138px]")}>
                {featuredText}
            </div>
        )
    };

    const renderOrderAgainHeaderTextSection = () => {
        if (shouldShowMenuTiles) return null;
        if (!productsForOrderAgain || productsForOrderAgain.length === 0) return null;

        return (
            <div id="order-again-text-header" className={clsx(
                "tw-text-black tw-text-2xl tw-mt-4 tw-pb-1 tw-px-2 tw-font-bold tw-mb-4 tw-z-10 tw-top-[138px]")}>
                Order Again
            </div>
        )
    };

    const renderCategoriesAndProducts = () => {
        // if we have sorted product data, use it to render the products
        if (sortedProductData) {
            return sortedProductData.map((productData) => {
                const [ category, products ] = productData;
                return (
                    <div key={category.id} className="product-category-row tw-px-2 tw-mb-4">
                        <ProductRow
                            isStoreOpen={isStoreOpen}
                            category={category}
                            company={company}
                            storeStyleTemplate={storeStyleTemplate}
                            // find the products that belong to the category
                            products={products}
                            productAvailabiltyMap={productAvailabiltyMap}
                            isOrderAgain={false}
                        />
                    </div>
                );
            });
        }

        // otherwise, render the products as normal based on the categories
        return categories.length > 0 && categories.map((category) => {
            return (
                <div key={category.id} className="product-category-row tw-px-2 tw-mb-4">
                    <ProductRow
                        isStoreOpen={isStoreOpen}
                        category={category}
                        company={company}
                        storeStyleTemplate={storeStyleTemplate}
                        // find the products that belong to the category
                        products={products.filter((product) => product.category_id === category.id)}
                        productAvailabiltyMap={productAvailabiltyMap}
                        isOrderAgain={false}
                    />
                </div>
            );
        });
    }

    const renderMenuTileGrid = () => {
        if (currentCategoryId !== -1) return null;
        if (![MENU_LAYOUTS.featured_primary_categories, MENU_LAYOUTS.featured_secondary_categories].includes(company.menu_layout)) return null;
        const allTitlesAreHidden = categories.every((category) => category.hide_title);
        
        return (
            <div className={clsx(
                "tw-container tw-px-2 xl:tw-mx-auto tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-3  xl:tw-grid-cols-5 tw-gap-3 md:tw-gap-7",
                isKioskMode && "tw-pb-[100px]"
                )} >
                {categories.map((category) => (
                    <div key={category.id} className="tw-flex tw-items-center tw-justify-center ">
                        <MenuTile
                            category={category}
                            isStoreOpen={isStoreOpen}
                            allTitlesAreHidden={allTitlesAreHidden}
                        />
                    </div>
                ))}
            </div>
        )
    }

    const renderFeaturedSection = () => {
        return (
            <div className="product-category-row tw-mb-4">
                <ProductRow
                    isDefaultGridView={true}
                    isStoreOpen={isStoreOpen}
                    category={{}}
                    storeStyleTemplate={storeStyleTemplate}
                    company={company}
                    products={sortedFeaturedProductData}
                    productAvailabiltyMap={productAvailabiltyMap}
                    isOrderAgain={false}
                />
            </div>
        );
    }

    const renderOrderAgainSection = () => {
        if (!productsForOrderAgain || productsForOrderAgain.length === 0) return null;

        return (
            <div className="product-category-row tw-px-2 tw-mb-4">
                <ProductRow
                    isDefaultGridView={true}
                    isStoreOpen={isStoreOpen}
                    category={{}}
                    storeStyleTemplate={storeStyleTemplate}
                    company={company}
                    products={productsForOrderAgain}
                    productAvailabiltyMap={productAvailabiltyMap}
                    isOrderAgain={true}
                />
            </div>
        );
    }

    const renderBreadcrumb = () => {
        if (!document.getElementById('primary-store-sidebar')) {
            return null;
        }

        return (
            <StickyComponent topOffset={isKioskMode ? 66 : 132}>
                <div 
                    className={clsx("tw-px-2 tw-mt-2")}>
                    <StoreBreadcrumb company={company} />
                </div>
            </StickyComponent>
        );
    }

    return (
        <div className={clsx("tw-w-full tw-mx-auto tw-relative")}>
            {renderStoreAlertMessage()}
            {renderBreadcrumb()}
            <div className='tw-relative'>
                <Switch>
                    <Case condition={currentCategoryId === -1 && [MENU_LAYOUTS.featured_primary_categories, MENU_LAYOUTS.featured_secondary_categories].includes(company.menu_layout)}>
                        {renderFeaturedHeaderTextSection()}
                        {renderMenuTileGrid()}
                    </Case>
                    <Case condition={currentCategoryId === -1}>
                        {renderOrderAgainHeaderTextSection()}
                        {renderOrderAgainSection()}
                        {renderFeaturedHeaderTextSection()}
                        {renderFeaturedSection()}
                    </Case>
                    <Default>
                        {renderCategoriesAndProducts()}
                    </Default>
                </Switch>
            </div>
        </div>
    );
};

export default StoreProductGrid;