import React from 'react';
import clsx from 'clsx';
import { TagIcon } from 'lucide-react';
import { PhotoIcon } from '@heroicons/react/20/solid';

const ProductElement = ({ product, company, availabilityInfo, isStoreOpen, isOrderAgain, lazyLoadAvatar }) => {
    const { 
        id,
        title,
        minimum_age, 
        price, 
        avatarUrl, 
        active, 
        visible, 
        is_snap_eligible,
        promotion_details, 
        description, 
        identifier, 
        allow_additional_instructions, 
        featured, 
        tax,
        illicit, 
        is_shipping_eligible, 
        compare_at_price,
    } = product;

    // use the availabilityInfo to determine if the product is currently available in stock
    const {isAvailable, timeAvailable } = availabilityInfo;
    
    const hasCompareAtPrice = company.show_compare_at_price && compare_at_price && Number(compare_at_price) > 0 && Number(compare_at_price) > Number(price);
    const isAgeRestricted = minimum_age >= 21;

    const isSnapEligible = is_snap_eligible && company.has_ebt;

    // if product is neither active, nor visible, don't render it (soft deleted)
    if (!active || !visible) return null;
    const isClickable = isAvailable && isStoreOpen;


    const SnapEBTIcon = () => (
        <div className="tw-absolute -tw-top-3 -tw-left-3">
            <img className='tw-w-[30px]' src="https://deliver-images.s3.us-west-2.amazonaws.com/icons/snap.png" />
        </div>
    );

    const renderPromotionDetails = () => {
        return (
            <div className='tw-flex tw-flex-row tw-items-center tw-text-vroom-green-500 tw-font-semibold product-card-promotion'>
                <TagIcon size={16} className='tw-text-vroom-green-500 tw-fill-text-vroom-green-500 tw-mr-1 ' />
                {promotion_details}
            </div>
        );
    }

    const renderPrice = () => {
        if (hasCompareAtPrice) {
            return (
                <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
                    <span className='tw-line-through tw-text-gray-400'>${Number(compare_at_price).toFixed(2)}</span>
                    <span className='tw-font-semibold tw-text-lg tw-text-vroom-green-500'>${Number(price).toFixed(2)}</span>
                </div>
            );
        }
        if (price == 0) {
            return (
                <span className='tw-font-semibold tw-text-base'>Select for price</span>
            );
        } else {
            return (
                <span className='tw-font-semibold tw-text-base'>${Number(price).toFixed(2)}</span>
            );
        }
    }

    const renderProductNextAvailableOverlay = () => {
        const timeAvailableStringArray = timeAvailable.split('\n'); // split the time string so we can render it in two lines
        
        return (
            <div className="tw-absolute !tw-opacity-100 tw-z-10 tw-w-full tw-h-full tw-rounded-md tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-text-blue-600 tw-text-base">
                <span className='tw-font-bold tw-text-center'>{timeAvailableStringArray[0]}</span>
                <span className='tw-text-center'>{timeAvailableStringArray[1]}</span>
            </div>
        )
    }

    const handleAddToCart = () => {
        if (!isClickable) return; // don't allow adding to cart if the product is not available

        const event = new CustomEvent('showAddToCartModal', { detail: {
            product: product,
            isOrderAgain: isOrderAgain
        }});
        window.dispatchEvent(event);
    };

    return ( 
        <div data-id={id} onClick={handleAddToCart} 
            className={clsx(
                'product-card product-box tw-relative tw-w-36 tw-h-[260px] md:tw-h-[300px] md:tw-w-[200px] lg:tw-w-[220px] !tw-p-1  md:!tw-p-2 tw-flex tw-flex-col tw-justify-start tw-gap-2 tw-rounded-md tw-mt-[10px]',
                isAvailable && 'hover:tw-bg-vroom-gray-200 tw-cursor-pointer',
                !isStoreOpen && 'tw-opacity-50 unable-to-order'
                )} >
            {/* Icons */}
            {/* Image */}
            <span className='tw-relative tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-md tw-p-1 tw-border tw-border-solid tw-border-vroom-gray-400 product-img-wrapper'>
                {isSnapEligible && <SnapEBTIcon />}
                {!isAvailable && renderProductNextAvailableOverlay()}
                {avatarUrl === "/avatars/original/missing.png" && <div className='tw-w-28 tw-h-32 md:tw-p-0  md:tw-w-44 md:tw-h-44  tw-flex tw-items-center tw-justify-center'><PhotoIcon className='tw-size-10 tw-text-vroom-gray-600' /></div>}
                {avatarUrl !== "/avatars/original/missing.png" &&  <img loading={lazyLoadAvatar ? 'lazy' : 'eager'} src={avatarUrl} alt={title} className={clsx(!isAvailable && 'tw-opacity-50  tw-blur','tw-w-28 tw-h-32 md:tw-p-0  md:tw-w-44 md:tw-h-44 tw-object-contain')} />}
            </span>
            {/* Details */}
            <section className={clsx(!isAvailable && 'tw-text-black/50' ,'tw-flex tw-flex-col tw-text-left tw-w-full')}>
                <span className='tw-font-semibold tw-text-lg'>{renderPrice()}</span>
                <span className='tw-font-normal product-card-title'>
                    {title}
                </span>
                <span>
                    {promotion_details && renderPromotionDetails()}
                </span>
            </section>
        </div>
    );
}
export default ProductElement;