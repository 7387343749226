import React from 'react';
import clsx from 'clsx';

const MenuTile = ({ category, isStoreOpen, allTitlesAreHidden }) => {

    const { id, title, hide_title, avatarUrl } = category;

    const handleCategoryBoxClick = () => {
        // find the tab that corresponds to this category and click on it
        document.querySelector(`.tab[data-id="${id}"]`).click();
    };

    return ( 
        <div data-id={id} onClick={handleCategoryBoxClick} 
            className={clsx(
                !isStoreOpen && 'tw-opacity-50',
                'category-box tw-relative tw-cursor-pointer tw-w-full tw-aspect-1 md:tw-w-[200px] lg:tw-w-[220px] !tw-p-1  md:!tw-p-2 tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-rounded-md',
            )} >
            {/* Image */}
            <span className='tw-relative tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-md tw-p-1 tw-border tw-border-solid tw-border-vroom-gray-400 product-img-wrapper'>
                <img loading='lazy' src={avatarUrl} alt={title} className={clsx('tw-w-full tw-aspect-1  md:tw-p-0  md:tw-w-44 md:tw-h-44 tw-object-contain tw-rounded-md')} />
            </span>
            {/* Details */}

            { !allTitlesAreHidden &&
                <section className='tw-flex tw-flex-col tw-w-full tw-text-center'>
                    <span className='tw-h-14 tw-text-[16px] tw-font-medium'>
                        {!hide_title && title}
                    </span>
                </section>
            }
        </div>
    );
}
export default MenuTile;