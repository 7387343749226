import { Application } from "@hotwired/stimulus";
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start();
// application.debug = true; // enable debugging if needed

window.Stimulus = application;
/* 
    Find all the Stimulus controllers in the project 
*/

// Generic controllers
const genericControllers = import.meta.glob("../../frontend/**/*_controller.{js,ts}", { eager: true });
registerControllers(application, genericControllers);

// Controllers from components
const componentControllers = import.meta.glob("../components/**/controller.{js,ts}", { eager: true });
for (const path in componentControllers) {
    try {
        let module = componentControllers[path];
        const componentModule = path.match(/components\/(.*)\/controller.js/);
        const componentName = componentModule ? componentModule[1] : null;
        if (componentName) {
            application.register(componentName, module.default);
        }
    } catch (error) {
        // skip registering the controller if we're not using Stimulus
    }
}

export default application;