import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    initialize() {
        // modals should be hidden by default, so we have no state to update on init
        this.watchTabClicks();
    }

    watchTabClicks() {
        // Tab Click is handled by Company Controller
        // Watch for nested tabs that are added dynamically and attach event listeners to them
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.classList && node.classList.contains("nested-tab")) {
                            node.addEventListener("click", this.handleNestedTabClick.bind(this));
                        }
                    });
                }
            }
        });

        observer.observe(this.element, { childList: true, subtree: true });
    }

    handleNestedTabClick(event) {
        const tab = event.target;

        // clear any active classes from  parent anything inside .sidebar-tabs with .active class
        const parentTabs = this.element.querySelectorAll(".sidebar-tabs .active");
        parentTabs.forEach(parentTab => {
            parentTab.classList.remove("active");
        });

        // add active class to the clicked nested tab
        tab.classList.add("active");
        // close the sidebar
        document.querySelector(".store-sidebar-modal-close-button").click();

        // find & scroll the category into view if it's not already visible 
        const category = document.querySelector(`.product-row[data-id="${tab.getAttribute("data-id")}"]`);
        const productCard = category.querySelector('.product-card');

        $('html,body').animate({
            scrollTop: $(productCard).offset().top - ( $(window).height() - $(productCard).outerHeight(true) ) / 2 - 50
        }, 200);
    }

    disconnect() {
        // cleanup
        // disconnect the observer
        this.observer.disconnect();
    }
}